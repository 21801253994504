
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class TheSidebar extends Vue {
    public links = [
      { title: 'sidebar.insights', link: '/insights', icon: 'newspaper' },
      { title: 'sidebar.bookmakers', link: '/bookmakers', icon: 'user-tie' },
      { title: 'sidebar.bookmakerPromotions', link: '/bookmaker-promotions', icon: 'percentage' },
      { title: 'sidebar.competitions', link: '/competitions', icon: 'trophy' },
      { title: 'sidebar.transactions', link: '/transactions', icon: 'hand-holding-usd' },
      { title: 'sidebar.users', link: '/users', icon: 'user' },
      { title: 'sidebar.referralCodes', link: '/referral-codes', icon: 'sitemap' },
      { title: 'sidebar.bots', link: '/bots', icon: 'robot' },
      { title: 'sidebar.sports', link: '/sports', icon: 'volleyball-ball' },
      { title: 'sidebar.leagues', link: '/leagues', icon: 'baseball-ball' },
      { title: 'sidebar.banner', link: '/banner', icon: 'bookmark' },
      { title: 'sidebar.settings', link: '/settings', icon: 'cogs' },
      { title: 'sidebar.documentation', link: '/documentation', icon: 'book' }
    ];
  }
